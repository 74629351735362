import Axios from '../../libs/nabdIns';

export default {
  state: {
    // streams: [],
    videoStream: {},
    audioStream: {},
    media: []
  },

  getters: {
    // videoStream: (state) => state.videoStream,
    // audioStreams: (state) => state.audioStreams,
    allMedia: (state) => state.media.filter(file => file.file != null),
  },

  mutations: {
    // setVideoStream(state, payload) {
    //   state.setMedia = [...payload]
    // },
    setMedia(state, payload) {
      state.media = payload
    },
    addImageToallMedia(state, payload) {
      state.media.unshift(payload);
    },
    // setAudioStream(state, payload) {
    //   state.audioStream = {...payload}
    // },
    // removeStream(state, payload) {
    //   let index = state.streams.findIndex(el => el.id == payload);
    //   state.streams.splice(index, 1);
    // }
  },

  actions: {
    getMedia({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/media`, {}, {
          params: {
            limit:  "1000",
            type: payload.type
          }
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setMedia', res.data.results.data);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },  
    // createMedia({commit}, payload) {
    //   let fd = new FormData();
    //   // console.log(payload)
    //   fd.append('key', 'media');
    //   payload.forEach(file => {
    //     fd.append('images[][file]', file);
    //   });
    //   if(payload.deletedImages) {
    //     payload.deletedImages.forEach((deletedFile, index) => {
    //       fd.append(`images[${index}][id]`, deletedFile.id);
    //       fd.append(`images[${index}][delete]`, 1);
    //     })
    //   }
    //   return new Promise ((resolve, reject) => {
    //     Axios.post(`/admin/media/save`, fd, {
    //     })
    //     .then(res => {
    //       if(res.data.results != null) {
    //         // commit('setVideoStream', res.data.results);
    //         resolve(res);
    //       }else {
    //         reject(res)
    //       }
    //     }).catch(err => {
    //       reject(err)
    //     });
    //   })
    // }, 
    createMedia({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/media/save`, {
          file_id: payload
        })
        .then(res => {
          if(res.data.results != null) {
            // commit('setVideoStream', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getMediaById({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/media/show/${payload}`)
        .then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        });
      })
    },
    deleteMedia({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/media/delete/${payload}`)
        .then(res => {
          if(res.data.results != null) {
            // commit('setVideoStream', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
  //   createSolution({commit}, payload) {
  //     console.log(payload)
  //     return new Promise ((resolve, reject) => {
  //       Axios.post(`/admin/solutions/save`, {
  //         id: payload.id,
  //         title: {
  //           ar: payload.titles.ar,
  //           en: payload.titles.ar,
  //         },
  //         description: {
  //           ar: payload.descriptions.ar,
  //           en: payload.descriptions.ar,
  //         },
  //         postion: {
  //           top: payload.top.toString(),
  //           left: payload.left.toString(),
  //         },
  //         category: payload.category,
  //         details_url: payload.details_url.toString(),
  //         video_url: payload.video_url,
  //         status: payload.status,
  //         images: payload.base64
  //       })
  //       .then(res => {
  //         if(res.data.results != null) {
  //           resolve(res);
  //         }else {
  //           reject(res)
  //         }
  //       }).catch(err => {
  //         reject(err)
  //       });
  //     })
  //   }, 
  //   deleteSolutions({commit}, payload) {
  //     return new Promise ((resolve, reject) => {
  //       Axios.post(`/admin/solutions/delete/${payload}`, {})
  //       .then(res => {
  //         if(res.data.results != null) {
  //           commit('removeSolution', res.data.results);
  //           resolve(res);
  //         }else {
  //           reject(res)
  //         }
  //       }).catch(err => {
  //         reject(err)
  //       });
  //     })
  //   }, 
  }
}