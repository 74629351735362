import Vue from 'vue';
import Vuex from 'vuex';
import VueCryptojs from 'vue-cryptojs';


// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

import users from './users/users';
import orders from './orders/orders';
import auth from './auth/auth';
import products from './products/products';

// import projects from './projects/projects';
import jobs from './jobs/jobs';
import categories from './categories/categories'

import pages from './pages/pages';
import sections from './sections/sections';
import sliders from './sliders/sliders';
import labels from './labels/labels';
import testmonials from './testmonials/testmonials';
import solutions from './solutions/solutions';
import roles from "./roles/roles";

import subscribe from './subscribe/subscribe';
import contactus from './contactus/contactus';

import settings from './settings/settings';

import news from "./News/News";
import presenters from "./presenters/presenters";
import shows from "./shows/shows";
import streams from "./streams/streams";
import media from "./media/media";
import mediav2 from "./media/media_v2";

Vue.use(Vuex);
Vue.use(VueCryptojs);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    users,
    orders,
    auth,
    products,
    categories,
    // offers,
    // projects,
    contactus,
    roles,
    testmonials,
    jobs,
    sections,
    pages,
    solutions,
    labels,
    settings,
    sliders,
    subscribe,
    news,
    presenters,
    shows,
    streams,
    media,
    mediav2,
  },
  strict: process.env.DEV,
});
