import axios from 'axios';
import store from '../store/auth/auth';
import router from '../router/index';

const generalInstance = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_URL,
});

generalInstance.interceptors.request.use(function (config) {
  // store.mutations.setLoading(store.state, true);
  let user = store.state.user;
  let token = user ? user.token : null;
  config.headers.Authorization = `Bearer ${token}`;
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

generalInstance.interceptors.response.use(function (response) {

  store.mutations.setLoading(store.state, false);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  store.mutations.setLoading(store.state, false);
  // if (error.response.status == 401 && error.response.data.results.message == "Unauthorized.") {
  //   localStorage.removeItem('u_n360d');
  //   localStorage.removeItem('up_n360d');
  //   setTimeout(() => {
  //     router.go();
  //   }, 500);
  // }
  return Promise.reject(error);
});
// Vue.prototype.$http = axiosIns

export default generalInstance;
