import Axios from '../../libs/generalInstance';

export default {
  state: {
    allFiles: [],
    dropzoneFiles: [],
    fileById: {},
    uploadingFiles: [],
    uploadingErr: [],
  },

  getters: {
    // videoStream: (state) => state.videoStream,
    // audioStreams: (state) => state.audioStreams,
    uploadingFiles: (state) => state.uploadingFiles,
    uploadingErr: (state) => state.uploadingErr,
    allFiles: (state) => state.allFiles,
    dropzoneFiles: (state) => state.dropzoneFiles,
    fileById: (state) => state.fileById,
  },

  mutations: {
    // setVideoStream(state, payload) {
    //   state.setMedia = [...payload]
    // },
    setFileById(state, payload) {
      state.fileById = payload;
    },
    setAllFiles(state, payload) {
      state.allFiles = payload;
    },
    setDropzoneFiles(state, payload) {
      state.dropzoneFiles = payload;
    },
    addImageToallFiles(state, payload) {
      state.allFiles.unshift(payload);
    },
    setUploadingFile(state, payload) {
      state.uploadingFiles.push({
        id: payload.id,
        file: payload.file,
        src: payload.src,
        progress: 0
      });
    },
    setUpdateProgress(state, payload) {
      let index = state.uploadingFiles.findIndex(upFile => upFile.id == payload.id);
      state.uploadingFiles[index].progress = payload.progress;
    },
    setUploadingErr(state, payload) {
      console.log("setUploadingErr => payload:", payload)
      state.uploadingErr.push(payload);
    },
    clearUploadingErr(state, payload) {
      state.uploadingErr = [];
    },
    removeUploadingFile(state, payload) {
      let index = state.uploadingFiles.findIndex(upFile => upFile.id == payload);
      state.uploadingFiles.splice(index, 1);
    },
    dataFieldUpdated(state, payload) {
      // let updatedFile = state.allFiles.find(file => file.id == payload.id); 
      let index = state.allMedia.findIndex(file => file.file.id == payload.id); 
      if(index != -1) {
        let updatedFile = state.allMedia[index];
        let updatedData = {
          ...updatedFile.file,
          ...payload,
        }
        state.allFiles[index].file = updatedData;
      }
    },
    // setAudioStream(state, payload) {
    //   state.audioStream = {...payload}
    // },
    // removeStream(state, payload) {
    //   let index = state.streams.findIndex(el => el.id == payload);
    //   state.streams.splice(index, 1);
    // }
  },

  actions: {
    getDropzoneFiles({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.get(`/admin/files`, {
          params: {
            page: payload.page,
            limit: payload.limit || 1000,
          }
        })
        .then(res => {
          commit('setDropzoneFiles', res.data.data);
          resolve(res);
        }).catch(err => {
          reject(err)
        });
      })
    },  
    getAllFiles({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.get(`/admin/files`, {
          params: {
            page: payload.page,
            limit: payload.limit || 1000,
            "mime_type[eq]": payload.mime_type,
            mime_type: 'image'
          }
        })
        .then(res => {
          if(res.data.data != null) {
            commit('setAllFiles', res.data.data);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },  
    getFileById({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.get(`/admin/files/${payload}`, {
        }, {
        })
        .then(res => {
          if(res.data.data != null) {
            commit('setFileById', res.data.data);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },  
    updateFile({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.put(`/admin/files/save`, payload)
        .then(res => {
          let resData = res.data.results;
          commit('dataFieldUpdated', {
            id: resData.id,
            titles: resData.titles,
            descriptions: resData.descriptions,
            keywords: resData.keywords,
          })
          resolve(res);
        }).catch(err => {
          reject(err)
        });
      })
    },
    saveFile({commit}, payload) {
      let fd = new FormData();
      
      fd.append('file', payload.file);
      fd.append('title', payload.file.title || 'title');
      fd.append('description', payload.file.description || 'description');
      fd.append('keywords', payload.file.keywords || 'keyword');

      var config = {
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          
          //* add progress to file data
          commit('setUpdateProgress', { id: payload.id, progress: percentCompleted });
        }
      };

      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/files/upload`, fd, config)
        .then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteFile({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.delete(`/admin/files/${payload}`, {})
        .then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    progressUploadFile({ commit, dispatch }, payload) {
      return new Promise ((resolve, reject) => {
        //* add item to uploading Q
        commit('setUploadingFile', payload) 
        //* run request for uploading
        dispatch('saveFile', payload)
        .then(res => {
          //* file uploaded
          //* attach it to media
          if(payload.attachToMedia) {
            dispatch('createMedia', res.data.data.id)
              .then(mediaRes => {
                resolve({
                  ...res.data.data,
                  file_id: mediaRes.data.results.file_id,
                  id: mediaRes.data.results.id,
                });
              }).catch(err => {
                reject({
                  err,
                  payload
                });
              })
          } else {
            resolve(res.data.data);
          }
            
          commit('removeUploadingFile', payload);
        }).catch(err => {
          //* file didnt upload
          commit('removeUploadingFile', payload);
          commit('setUploadingErr', { err, payload });
          reject({
            err,
            payload
          });
        })
      })
    }
  }
}