import Axios from '../../libs/nabdIns';

export default {
  state: {
    // settings: [],
    settings: {},
    
    // ordersStatus: [],
    // orderStatus: {},
  },

  getters: {
    // suppliers: (state) => state.suppliers,
    settings: (state) => state.settings,
    // ordersStatus: (state) => state.ordersStatus,
    // orderStatus: (state) => state.orderStatus,
  },

  mutations: {
    // setSuppliers(state, payload) {
    //   state.suppliers = [...payload]
    // },
    setSettings(state, payload) {
      state.settings = {...payload}
    },

  },

  actions: {
    getSettings({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/settings`, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setSettings', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    updateSettings({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/settings/save`, {
          settings: {

            location_company: payload.location_company,
            address: payload.address,
            email: payload.email,
            phone: payload.phone,
            social_media_links: payload.social_media_links,
            facebook_pixel: payload.facebook_pixel,
            google_tag_manager: payload.google_tag_manager,
            maps_api_key: payload.maps_api_key,
            site_meta_title: payload.site_meta_title,
            site_meta_description: payload.site_meta_description,
            copy_rights: payload.copy_rights,
            AppName: payload.AppName,
            siteName: payload.siteName,
            favicon: payload.favicon,
            colorTheme: payload.colorTheme,
            fonts: payload.fonts,
            fax: payload.fax,
            keyWords: payload.keyWords,
            footerNumberOfColomns: payload.footerNumberOfColomns,
            footerSections: payload.footerSections,
            dark_logo: payload.dark_logo,
            logo: payload.logo,
          }
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    
  }
}